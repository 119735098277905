
/* eslint-disable */
import { defineComponent } from 'vue';
import Avatar from 'primevue/avatar';

export default defineComponent({
  name: 'HomeView',
  components: {
    Avatar,
  },
});
