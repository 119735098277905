import { createApp } from 'vue';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import Toast from 'primevue/toast';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import SplitButton from 'primevue/splitbutton';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import ContextMenu from 'primevue/contextmenu';
import ConfirmDialog from 'primevue/confirmdialog';
import pinia from './stores';
import App from './App.vue';
import router from './router';
import 'primevue/resources/themes/lara-light-indigo/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primeflex/themes/primeone-light.css';

const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(PrimeVue);
app.use(ToastService);
app.use(ConfirmationService);
// eslint-disable-next-line
app.component('Button', Button);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('SplitButton', SplitButton);
// eslint-disable-next-line
app.component('Password', Password);
// eslint-disable-next-line
app.component('Dropdown', Dropdown);
// eslint-disable-next-line
app.component('Toast', Toast);
// eslint-disable-next-line
app.component('ContextMenu', ContextMenu);
app.component('ConfirmDialog', ConfirmDialog);
app.mount('#app');
