import { defineStore } from 'pinia';
import { ref } from 'vue';
import AppSession from '@/models/app-session';

const useSessionStore = defineStore('session', {
  state: () => ({ session: ref<AppSession>() }),
  getters: {
    isLoggedIn(state): boolean {
      return (!!state.session?.token);
    },
  },
  actions: {
    set(newSession: AppSession) {
      this.session = newSession;
    },
    clear() {
      this.session = undefined;
    },
  },
});

export default useSessionStore;
