import axios from 'axios';
import useSessionStore from '@/stores/session';

const baseUrl = process.env.VUE_APP_API_BASE_URL;

axios.interceptors.request.use((request) => {
  const token = useSessionStore().$state.session?.token;

  // eslint-disable-next-line
  request.baseURL = baseUrl;
  // eslint-disable-next-line
  request.headers.Accept = 'application/json';
  // eslint-disable-next-line
  request.headers['Content-Type'] = 'application/json';

  if (token) {
    // eslint-disable-next-line
    request.headers.Authorization = 'Bearer '+ token;
  }

  return request;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      useSessionStore().clear();
    }

    return Promise.reject(error);
  },
);
