import User from './user';

class AppSession {
    token: string;

    user: User;

    constructor(token: string, user: User) {
      this.token = token;
      this.user = user;
    }
}

export default AppSession;
